<template>
    <b-container>
        <b-row>
            <b-col lg="12" sm="12">
                <form-input :load="load" :key="item.id" :data="formInputData" @change="setData" @submit="register" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { ggManageScheduleStore, ggManageScheduleUpdate } from '../../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common, Repository, helpers } from '@/mixins/helper-functions'
import CommonFunc from '@/Utils/common'

export default {
    props: ['item'],
    mixin: [Common],
    components: {
        FormInput
    },
    created () {
        const currentGrower = this.$store.state.ExternalUserIrrigation.ginnerGrowerDetails
      const currentGrowerObj = {
        name: currentGrower.name,
        name_bn: currentGrower.name_bn,
        father_name: currentGrower.father_name,
        father_name_bn: currentGrower.father_name_bn,
        district_id: currentGrower.district_id,
        upazilla_id: currentGrower.upazilla_id
      }
        Object.assign(this.parentObject, currentGrowerObj)
        delete this.parentObject.id

        if (this.item.id) {
            const tmp = JSON.parse(JSON.stringify(this.item))
            Object.assign(this.parentObject, tmp)
            this.parentObject = Object.assign({}, this.parentObject, {
                cotton_variety_ids: JSON.parse(tmp.cotton_variety_ids),
                cotton_ids: JSON.parse(tmp.cotton_ids)
            })
            this.getCottonNameList()
        }
        Object.assign(this.parentObject, { applicant_id: this.$store.state.ExternalUserIrrigation.ginnerGrowerDetails.id })
    },
    data () {
        return {
            isFinal: false,
            parentObject: {
              applicant_id: '',
              region_id: '',
              zone_id: '',
              unit_id: '',
              grower_id: '',
              cotton_variety_ids: [],
              cotton_ids: [],
              type: 2,
              district_id: 0,
              upazilla_id: 0
            },
            growerList: [],
            zoneList: [],
            unitList: [],
            cottonNameList: [],
            growerListLoading: false,
            passType: 'password',
            load: false,
            reloadComponent: 1,
            hatList: []
        }
    },
    computed: {
        districtList () {
           return this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
        },
        upazilaList () {
          return this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)
        },
        regionList () {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.regionList.filter(item => item.status === 1)
        },
        fiscalYearList () {
            // return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
            return CommonFunc.getFiscalYearList()
        },
        cottonVarietyList () {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.cottonVaritiesList.filter(item => item.status === 1)
        },
        seasonList () {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.seasonList.filter(item => item.status === 1)
        },
        // hatList () {
        //     return this.$store.state.ExternalUserIrrigation.agriMarketing.hatList.filter(item => item.status === 1)
        // },
        isEn () {
          return this.$i18n.locale === 'en'
        },
        formInputData () {
            return {
                chunk: true,
                buttonColCustomClass: 'text-right',
                buttonLocale: this.item ? 'globalTrans.update' : 'globalTrans.save',
                buttonContainerClass: 'justify-content-end',
                buttonClass: 'btn btn-primary',
                hasCancelButton: true,
                buttonCancelClass: 'btn btn-danger',
                modalId: 'modal-4',
                dataParent: this.parentObject,
                data: [
                    {
                        head: {
                            title: ''
                        },
                        items: [
                            {
                                name: 'fiscal_year_id',
                                type: 'Select',
                                label: 'sidebar.warehouseSubNav.fiscal_year',
                                options: helpers.filterListLang(this.fiscalYearList, 'text_en'),
                                labelCol: 4
                            },
                            {
                                name: 'schedule_date',
                                type: 'Datepicker',
                                label: 'cotton_ginner_grower.schedule_date',
                                labelCol: 4
                            },
                            {
                                name: 'region_id',
                                type: 'Select',
                                label: 'globalTrans.region',
                                options: this.regionList,
                                labelCol: 4
                            },
                            {
                                name: 'zone_id',
                                type: 'Select',
                                label: 'cotton_config.zone_name',
                                options: helpers.filterListLang(this.zoneList),
                                labelCol: 4
                            },
                            {
                                name: 'unit_id',
                                type: 'Select',
                                label: 'cotton_config.unit_name',
                                options: helpers.filterListLang(this.unitList),
                                labelCol: 4
                            },
                            {
                                name: 'district_id',
                                type: 'Select',
                                label: 'globalTrans.district',
                                options: this.districtList,
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'upazilla_id',
                                type: 'Select',
                                label: 'globalTrans.upazila',
                                options: this.upazilaList,
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'name',
                                type: 'Input',
                                label: 'cotton_ginner_grower.company_name_en',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'name_bn',
                                type: 'Input',
                                label: 'cotton_ginner_grower.company_name_bn',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                label: 'cotton_ginner_grower.ginner_name_en',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                label: 'cotton_ginner_grower.ginner_name_bn',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'cotton_variety_ids',
                                type: 'Select',
                                label: 'cotton_ginner_grower.cottonVariety',
                                options: helpers.filterListLang(this.cottonVarietyList),
                                labelCol: 4,
                                rules: 'nullable',
                                isMultiple: true
                            },
                            {
                                name: 'cotton_ids',
                                type: 'Select',
                                label: 'cotton_ginner_grower.cottonName',
                                options: helpers.filterListLang(this.cottonNameList),
                                labelCol: 4,
                                rules: 'nullable',
                                isMultiple: true
                            },
                            {
                                name: 'seasons_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.season',
                                options: helpers.filterListLang(this.seasonList),
                                labelCol: 4
                            },
                            {
                                name: 'hatt_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.hat_name',
                                options: helpers.filterListLang(this.hatList),
                                labelCol: 4
                            },
                            {
                                name: 'quantity',
                                type: 'Input',
                                label: 'pump_install.quantity',
                                labelCol: 4
                            },
                            {
                                name: 'remarks',
                                type: 'Textarea',
                                label: 'cotton_config.remarks_en',
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'remarks_bn',
                                type: 'Textarea',
                                label: 'cotton_config.remarks_bn',
                                labelCol: 4,
                                rules: 'nullable'
                            }
                        ]
                    }
                ]
            }
        }
    },
    watch: {
        'parentObject.region_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.zoneList = this.setZone(newVal)
            }
        },
        'parentObject.zone_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unitList = this.setUnit(newVal)
            }
        },
        'parentObject.cotton_variety_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.cottonNameList = this.setCottonNameList(newVal)
            }
        }
    },
    methods: {
        setZone (orgId) {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.zoneList.filter(el => el.region_id === orgId)
        },
        setUnit (zoneId) {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.unitList.filter(el => el.zone_id === zoneId)
        },
        setCottonNameList (cottonVarietyId) {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.filter(el => el.cotton_variety_id === cottonVarietyId)
        },
        setData (data) {
            this.parentObject = Object.assign(this.parentObject, data)
            this.getCottonNameList()
            this.setDistrictUpazila(this.parentObject.unit_id)
        },
        getData () {
            return this.$store.state.parentObject
        },
        async register () {
            this.load = true
            delete this.parentObject.status
            const result = await Repository.create({
                data: this.parentObject,
                baseURL: agriMarketingServiceBaseUrl,
                storeAPI: ggManageScheduleStore,
                updateAPI: ggManageScheduleUpdate,
                itemId: this.parentObject.id
            })
            this.load = false
            if (result.success) {
                this.$emit('submitted')
            }
        },
        getCottonNameList () {
            const cottonNameList = []
            if (this.parentObject.cotton_variety_ids.length) {
                this.parentObject.cotton_variety_ids.forEach((cottonVarietyId) => {
                    const cottonNameLists = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.filter(item => item.cotton_variety_id === cottonVarietyId)
                    cottonNameLists.forEach((item) => {
                        cottonNameList.push(item)
                    })
                })
                this.cottonNameList = cottonNameList
                // if the cotton id already selected, then update the cotton id
                // accrording to cotton variety id
                if (this.parentObject.cotton_ids.length) {
                    const idArr = []
                    this.parentObject.cotton_ids.forEach(id => {
                        const cottonNameObj = this.cottonNameList.find(obj => obj.value === parseInt(id))
                        if (cottonNameObj !== undefined) {
                            idArr.push(parseInt(cottonNameObj.value))
                        }
                    })
                    this.parentObject.cotton_ids = idArr
                }
            } else {
                this.cottonNameList = []
                this.parentObject.cotton_ids = []
            }
        },
        setDistrictUpazila (id) {
            const obj = this.$store.state.ExternalUserIrrigation.agriMarketing.unitList.find(item => item.value === parseInt(id))
            if (obj !== undefined) {
                // const district = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(dist => dist.value === obj.district_id)
                // const upazilla = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(upz => upz.value === obj.upazilla_id)

                Object.assign(this.parentObject,
                { district_id: obj.district_id },
                { upazilla_id: obj.upazilla_id }
                )
                this.hatList = this.getHatList(obj.district_id)
            }
        },
        getHatList (districtId) {
            const hatList = this.$store.state.ExternalUserIrrigation.agriMarketing.hatList.filter(item => item.district_id === parseInt(districtId))
            return hatList
        }
    }
}
</script>
