<template>
    <div>
        <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12">
                    <item-view :prop-data="itemsToView"/>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
    </div>
</template>
<script>
import ItemView from '@/components/structure/itemView'
import { numberFormatBn } from '@/Utils/fliter'
export default {
    name: 'Show',
    props: ['item'],
    components: { ItemView },
    data () {
        return {
            smartCardApplication: {},
            showData: {
                official: {},
                personal: {},
                address: {},
                land: {},
                application: {}
            }
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.$t('globalTrans.company_name'),
                    value1: this.isBn ? this.item.name_bn : this.item.name,
                    title2: this.$t('cotton_ginner_grower.ginnerName'),
                    value2: this.isBn ? this.item.father_name_bn : this.item.father_name
                },
                {
                    title1: this.$t('sidebar.warehouseSubNav.fiscal_year'),
                    value1: this.isBn ? this.item.fiscal_year_bn : this.item.fiscal_year_en,
                    title2: this.$t('cotton_ginner_grower.season'),
                    value2: this.isBn ? this.item.season_bn : this.item.season_en
                },
                {
                    title1: this.$t('cotton_ginner_grower.applicantId'),
                    value1: this.item.applicant_id,
                    title2: this.$t('globalTrans.region'),
                    value2: this.isBn ? this.item.region_bn : this.item.region_en
                },
                {
                    title1: this.$t('cotton_config.zone_name'),
                    value1: this.isBn ? this.item.zone_bn : this.item.zone_en,
                    title2: this.$t('seedsSeeds.unitName'),
                    value2: this.isBn ? this.item.unit_bn : this.item.unit_en
                },
                {
                    title1: this.$t('globalTrans.district'),
                    value1: this.isBn ? this.item.district_bn : this.item.district_en,
                    title2: this.$t('globalTrans.upazila'),
                    value2: this.isBn ? this.item.upazila_bn : this.item.upazila_en
                },
                {
                    title1: this.$t('cotton_ginner_grower.cottonName'),
                    value1: this.getCottonNames(this.item.cotton_ids),
                    title2: this.$t('cotton_ginner_grower.cottonVariety'),
                    value2: this.getCottonVarietyNames(this.item.cotton_variety_ids)
                },
                {
                    title1: this.$t('pump_install.quantity'),
                    value1: this.$n(this.item.quantity),
                    title2: this.$t('globalTrans.date'),
                    value2: this.$options.filters.dateFormat(this.item.schedule_date)
                },
                {
                    title1: this.$t('cotton_ginner_grower.remarks'),
                    value1: this.isBn ? this.item.remarks_bn : this.item.remarks,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            return {
                title: '',
                data:
                    [
                        {
                            title: this.$t('ginnerGrower.manageSchedule'),
                            type: 'table',
                            data: personalFormatted
                        }
                    ]
            }
        }
    },
    methods: {
        numberFormatBn (number) {
            return numberFormatBn(number, this, { useGrouping: false })
        },
        viewItems () {
            return this.itemsToView
        },
        getCottonVarietyNames (ids) {
            const idArr = JSON.parse(ids)
            if (Array.isArray(idArr)) {
                const arr = []
                idArr.forEach(id => {
                const obj = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonVaritiesList.find(item => item.value === parseInt(id))
                if (obj !== undefined) {
                    const name = this.isBn ? obj.text_bn : obj.text_en
                    arr.push(name)
                }
                })
                return arr.toString()
            } else {
                const obj = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonVaritiesList.find(item => item.value === parseInt(ids))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getCottonNames (ids) {
            const idArr = JSON.parse(ids)
            if (Array.isArray(idArr)) {
                const arr = []
                idArr.forEach(id => {
                    const obj = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.find(item => item.value === parseInt(id))
                    if (obj !== undefined) {
                        const name = this.isBn ? obj.text_bn : obj.text_en
                        arr.push(name)
                    }
                })
                return arr.toString()
            } else {
                const obj = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.find(item => item.value === parseInt(ids))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
    }
}
</script>
