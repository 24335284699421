<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('ginnerGrower.manageSchedule') + ' ' + $t('globalTrans.search') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes"
                             @change="setData"
                             @picker-instance="getDateInstance"
                             @returned-data="searchData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('ginnerGrower.manageSchedule') + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n((data.index + pagination.slOffset), { useGrouping: false }) }}
                                        </template>
                                        <template #cell(mobile_no)="data">
                                            {{ numberFormatBn(data.value) }}
                                        </template>
                                        <template #cell(quantity)="data">
                                            {{ $n(data.value) }}
                                        </template>
                                        <template #cell(schedule_date)="data">
                                            {{ data.value | dateFormat }}
                                        </template>
                                        <template #cell(status)="data">
                                            <span v-if="data.value == 2 " class="badge badge-success"><i class="fas fa-check"></i> {{ $t('globalTrans.approved') }}</span>
                                            <span v-else class="badge badge-danger"><i class="far fa-pause-circle"></i> {{ $t('globalTrans.incomplete') }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button-group>
                                                <b-button variant=" iq-bg-success mr-1" size="sm" @click="view(data.item)"><i class="far fa-eye"></i></b-button>
                                                <b-button v-if="data.item.status == 1" v-b-modal.modal-4 variant=" iq-bg-info" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></b-button>
                                            </b-button-group>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="loadData()"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="xl" :title="formTitle" :hide-footer=true :ok-title="$t('globalTrans.close')">
            <FormV :item="currentItem" :key="currentItem.id" ref="form" @submitted="loadData()"/>
        </b-modal>
        <b-modal id="view-modal" size="lg" :title="$t('globalTrans.details')" :hide-footer=true>
            <Show :item="currentItem" :key="currentItem.id" />
        </b-modal>
    </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { ggManageScheduleList } from '../../../api/routes'
import { helpers } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import MasterList from '@/mixins/modal-base-master-list'
import { numberFormatBn } from '@/Utils/fliter'
import Show from './Show'
// import Show from '@/modules/agri-marketing/cotton/ginner-grower/pages/manage-schedule/DetailModal.vue'

export default {
    name: 'ListView',
    mixins: [MasterList],
    components: {
        FormV, FormSearch, Show
    },
    data () {
        return {
            search: {},
            currentItem: { id: 0 },
            dateInstanceStore: [],
            dateInstance: []
        }
    },
    computed: {
        fiscalYearList () {
          const fiscalYearList = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
          return fiscalYearList.sort((a, b) => b.sorting_order - a.sorting_order)
        },
        regionList () {
            return this.$store.state.ExternalUserIrrigation.agriMarketing.regionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
        },
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        orgList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        },
        formTitle () {
            return !this.currentItem ? this.$t('ginnerGrower.manageSchedule') + ' ' + this.$t('globalTrans.entry') : this.$t('ginnerGrower.manageSchedule') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('sidebar.warehouseSubNav.fiscal_year'), class: 'text-left' },
                { label: this.$t('cotton_ginner_grower.zone'), class: 'text-left' },
                { label: this.$t('globalTrans.district'), class: 'text-left' },
                { label: this.$t('globalTrans.upazila'), class: 'text-left' },
                { label: this.$t('seedsSeeds.unitName'), class: 'text-left' },
                { label: this.$t('cotton_ginner_grower.ginnerName'), class: 'text-left' },
                { label: this.$t('cotton_ginner_grower.schedule_date'), class: 'text-left' },
                { label: this.$t('pump_install.quantity'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'fiscal_year_en' },
                { key: 'zone_en' },
                { key: 'district_en' },
                { key: 'upazila_en' },
                { key: 'unit_en' },
                { key: 'name' },
                { key: 'schedule_date' },
                { key: 'quantity' },
                { key: 'status' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'fiscal_year_bn' }
                keys[2] = { key: 'zone_bn' }
                keys[3] = { key: 'district_bn' }
                keys[4] = { key: 'upazila_bn' }
                keys[5] = { key: 'unit_bn' }
                keys[6] = { key: 'name_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            const vm = this
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'fiscal_year_id',
                        type: 'Select',
                        label: 'sidebar.warehouseSubNav.fiscal_year',
                        options: helpers.filterListLang(this.fiscalYearList, 'text_en'),
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {},
                    {
                        name: 'from_date',
                        type: 'Datepicker',
                        label: 'globalTrans.from_date',
                        config: {
                            onChange: function (selectedDates, dateStr) {
                                const toDate = vm.dateInstance[1].to_date
                                toDate.set('minDate', dateStr)
                                toDate.toggle()
                            }
                        }
                    },
                    {
                        name: 'to_date',
                        type: 'Datepicker',
                        label: 'globalTrans.to_date',
                        config: {
                            onChange (selectedDates, dateStr) {
                                const fromDate = vm.dateInstance[0].from_date
                                fromDate.set('maxDate', dateStr)
                            }
                        }
                    }
                ]
            }
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        getDateInstance (payload) {
            this.dateInstanceStore.push(payload)
            if (this.dateInstanceStore.length > 1) {
                this.dateInstance = this.dateInstanceStore
            }
        },
        edit (item) {
            this.currentItem = item
        },
        numberFormatBn (number) {
            return numberFormatBn(number, this, { useGrouping: false })
        },
        setData (data) {
            Object.assign(this.search, data)
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        view (item) {
            this.currentItem = item
            this.$bvModal.show('view-modal')
        },
        resetId () {
            this.currentItem = { id: 0 }
            // const data = {
            //   applicant_id: '',
            //   region_id: '',
            //   zone_id: '',
            //   unit_id: '',
            //   grower_id: '',
            //   cotton_variety_ids: [],
            //   cotton_ids: [],
            //   type: 2,
            //   district_id: 0,
            //   upazilla_id: 0
            // }
            // this.$refs.form.setData(data)
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        async loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, applicant_id: this.$store.state.ExternalUserIrrigation.ginnerGrowerDetails.id })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            await RestApi.getData(agriMarketingServiceBaseUrl, ggManageScheduleList, params).then((response) => {
                if (response.success) {
                    if (response.data.data.length > 0) {
                       response.data.data.map(item => {
                            const district = this.districtList.find(el => el.value === item.district_id)
                            if (typeof district !== 'undefined') {
                                item.district_en = district.text_en
                                item.district_bn = district.text_bn
                            }
                            const upazila = this.getUpazilaList().find(el => el.value === item.upazilla_id)
                            if (typeof upazila !== 'undefined') {
                                item.upazila_en = upazila.text_en
                                item.upazila_bn = upazila.text_bn
                            }
                            const region = this.regionList.find(el => el.value === item.region_id)
                            if (typeof region !== 'undefined') {
                                item.region_en = region.text_en
                                item.region_bn = region.text_bn
                            }
                            const zone = this.$store.state.ExternalUserIrrigation.agriMarketing.zoneList.find(el => el.value === item.zone_id)
                            if (typeof zone !== 'undefined') {
                                item.zone_en = zone.text_en
                                item.zone_bn = zone.text_bn
                            }
                            const unit = this.$store.state.ExternalUserIrrigation.agriMarketing.unitList.find(el => el.value === item.unit_id)
                            if (typeof unit !== 'undefined') {
                                item.unit_en = unit.text_en
                                item.unit_bn = unit.text_bn
                            }
                            const cotton = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.find(el => el.value === item.cotton_id)
                            if (typeof cotton !== 'undefined') {
                                item.cotton_name_en = cotton.text_en
                                item.cotton_name_bn = cotton.text_bn
                            }
                            const cottonVariety = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonVaritiesList.find(el => el.value === item.cotton_variety_id)
                            if (typeof cottonVariety !== 'undefined') {
                                item.cotton_variety_en = cottonVariety.text_en
                                item.cotton_variety_bn = cottonVariety.text_bn
                            }
                            const season = this.$store.state.ExternalUserIrrigation.agriMarketing.seasonList.find(el => el.value === item.seasons_id)
                            if (typeof season !== 'undefined') {
                                item.season_en = season.text_en
                                item.season_bn = season.text_bn
                            }
                            const fiscalYar = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(el => el.value === item.fiscal_year_id)
                            if (typeof fiscalYar !== 'undefined') {
                                item.fiscal_year_en = fiscalYar.text_en
                                item.fiscal_year_bn = fiscalYar.text_bn
                            }
                            return item
                        })
                    }
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }).catch((error) => {
                if (error.response) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        },
        getCustomizedDataList (data) {
          const customList = data.map(item => {
            const custmonItem = {}
            const district = this.districtList.find(el => el.value === item.district_id)
            if (typeof district !== 'undefined') {
              custmonItem.district_en = district.text_en
              custmonItem.district_bn = district.text_bn
            }
            const upazila = this.getUpazilaList().find(el => el.value === item.upazilla_id)
            if (typeof upazila !== 'undefined') {
              custmonItem.upazila_en = upazila.text_en
              custmonItem.upazila_bn = upazila.text_bn
            }
            const region = this.regionList.find(el => el.value === item.region_id)
            if (typeof region !== 'undefined') {
              custmonItem.region_en = region.text_en
              custmonItem.region_bn = region.text_bn
            }
            const zone = this.$store.state.ExternalUserIrrigation.agriMarketing.zoneList.find(el => el.value === item.zone_id)
            if (typeof zone !== 'undefined') {
              custmonItem.zone_en = zone.text
              custmonItem.zone_bn = zone.text_bn
            }
            const unit = this.$store.state.ExternalUserIrrigation.agriMarketing.unitList.find(el => el.value === item.unit_id)
            if (typeof unit !== 'undefined') {
              custmonItem.unit_en = unit.text
              custmonItem.unit_bn = unit.text_bn
            }
            const cotton = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonNameList.find(el => el.value === item.cotton_id)
            if (typeof cotton !== 'undefined') {
              custmonItem.cotton_name_en = cotton.text
              custmonItem.cotton_name_bn = cotton.text_bn
            }
            const cottonVariety = this.$store.state.ExternalUserIrrigation.agriMarketing.cottonVaritiesList.find(el => el.value === item.cotton_variety_id)
            if (typeof cottonVariety !== 'undefined') {
              custmonItem.cotton_variety_en = cottonVariety.text
              custmonItem.cotton_variety_bn = cottonVariety.text_bn
            }
            const season = this.$store.state.ExternalUserIrrigation.agriMarketing.seasonList.find(el => el.value === item.seasons_id)
            if (typeof season !== 'undefined') {
              custmonItem.season_en = season.text
              custmonItem.season_bn = season.text_bn
            }
            const fiscalYar = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(el => el.value === item.fiscal_year_id)
            if (typeof fiscalYar !== 'undefined') {
              custmonItem.fiscal_year_en = fiscalYar.text_en
              custmonItem.fiscal_year_bn = fiscalYar.text_bn
            }
            return Object.assign({}, item, custmonItem)
          })
          return customList
        }
    }
}
</script>
